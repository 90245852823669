























































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import ModalView from '@/components/helper/modal-view.vue';
import FormBase from '@/components/helper/form-base.vue';
import Util from '@/lib/util'
import url from '@/lib/url'
import Application from '@/store/entities/registryoffices/application';
import ListBase from '@/lib/listbase';

@Component({
    components: { FormBase, ModalView }, 
    watch: {
        value: function (val) { 
            let obj = (this as any);
            obj.modalShow = val;
            if (val) {
                obj.initForm();
            } else 
                this.$emit('input', false);
        },
        modalShow: function (val) {
            if (!val) this.$emit('input', false);
        }
    }
})

export default class ApplicationForm extends ListBase {
    entity: string = 'application';
    @Prop({ type: Boolean, default: false }) value: boolean;
    application: Application = new Application();
    modalShow: boolean = false;
    modalSetting: any = {
        entity: "application",
        titleFull: 'Solicitud',
        saveText: 'Actualizar',
        width: '1000px',
        showForm: false,
        disabledSave: false,
    };
    
    get loading() {
        return this.$store.state.application.loading;
    };

    get current_state() {
        if (this.application == undefined || this.application.state_id == undefined) return {};
        return Util.abp.list.getItem(this.application.state_id);
    }

    get states() {
        return Util.abp.list.getList('E_APPLICATION');
    }

    get isAdmin() {
        return Util.abp.session.isAdmin;
    }

    get isAproveer() {
        return Util.abp.session.isAdmin || Util.abp.session.isAproveer();
    }
                
    items = [
        this.gItem({ type: 'price', prop: 'amount', label: 'Monto', size: 4 }),
        this.gItem({ type: 'textarea', prop: 'comments', label: 'Comentarios', size: 12, rows: 3 }),            
    ];

    getInitials(string) {
        return Util.getInitials(string);
    }

    changeState(item) {
        this.application.state_id = item.id;
    }

    showHistory() {
        this.$emit('show-history', this.application);
    }

    showImage() {
        this.$emit('show-image', this.application);
    }
    
    initForm() {
        this.application = Util.extend(true, {}, this.$store.state.application.editModel); 
        let type = Util.abp.list.getItem(this.application.type_id);            
        this.modalSetting.titleFull = type.name;
        this.modalSetting.disabledSave = !this.canEdit();
    }

    async save() {
        if ((this.$refs.entityForm1 as any).validate()) {
            let model = Util.extend(true, {}, this.application); 

            await this.$store.dispatch({
                type: this.entity + '/update',
                data: model
            });
            this.$emit('save-success');
            this.cancel();

            Util.abp.notify.success(this, 'Solicitud actualizada!');
        } 
    }        

    cancel() {
        this.$emit('input', false);
        (this.$refs.entityForm1 as any).reset();
    }

    mounted() {       

    }

    created() {
    }
}
